.contact-banner {
    position: relative;

}

.contact-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/contactus-header-banner.png');
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.contact-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 300px;
}

.contact-side-color{
}

.contact-side-color::after{
    content: "";
    position: absolute;
    width: 25%;
    height: 950px;
    background-color: #B9D738;
    right: 0;
    top: 60px;
    bottom: 0;
    z-index: -1;
}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .cinput{
        margin-left: 0 !important;
    }
    .contact-side-color::after{
        content: "";
        position: absolute;
        width: 25%;
        height: 950px;
        background-color: #B9D738;
        right: 0;
        top: 60px;
        bottom: 0;
        z-index: -1;
    }
    .map{
        padding: 0 !important;
        margin-top: 70px !important;
    }
    .map iframe{
        width: 100%;
    }
    .address{
        width: auto !important;
        height: 180px !important;
        margin-top: -180px !important;
    }
    .address .secol{
        padding-left: 3rem;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
    .map iframe{
        width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .map iframe{
        width: 100%;
    }
    .address{
        width: auto !important;
        height: 180px !important;
        margin-top: -180px !important;
    }
    .address .secol{
        padding-left: 3rem;
    }
   
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {

   
}