.microfact-banner {
    position: relative;

}

.microfact-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/microfact-banner.png');
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.microfact-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 300px;
}

.microfact-carousal::before {
    content: "";
    /* background-image: url('../images/circle.png');
    background-repeat: no-repeat; */

    border: 2px solid #B9D738;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -100px;
    margin-top: 20px;
    width: 450px;
    z-index: 0;
    height: 450px;
    border-radius: 100%;
}

.microfact-carousal2::before {
    content: "";
    /* background-image: url('../images/circle.png');
    background-repeat: no-repeat; */

    border: 2px solid #f6f6f6;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 300px;
    margin-top: 20px;
    width: 450px;
    z-index: 0;
    height: 450px;
    border-radius: 100%;
}



.micro-banner{
    background-image: url('../images/micro-bg-banner.png');
    background-repeat: no-repeat;
}

.micro-banner-content{
    color: white;
    padding: 30px;
    width: 130%;
    height: 140px;
    background-color: #55585A;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-left: -30%;
    text-align: center;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 18% 100%);
}

.manufact_carousal .react-multiple-carousel__arrow{
    display: none;
}

.manufact_carousal .react-multi-carousel-dot-list {
    position: absolute;
    bottom: unset !important;
    display: flex;
    left: unset !important;
    right: 5%;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    flex-direction: column;
}




@media screen and (min-width: 0px) and (max-width: 576px) {
    .micro-banner-content{
        /* display: none; */
        color: white;
        padding: 30px;
        width: 130%;
        height: 140px;
        background-color: #55585A;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-left: -23%;
        text-align: center;
        clip-path: polygon(0 0%, 100% 0%, 100% 100%, 18% 100%);
    }
    .micro-banner-content h2{
        font-size: 18px;
    }

    .manufact_carousal .react-multi-carousel-dot-list {
        position: absolute;
        bottom: 0 !important;
        display: flex;
        left: 0 !important;
        right: 0;
        justify-content: center;
        margin: auto;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        flex-direction: row !important;
    }
    .hashpadding{
        padding-top: 0px !important;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
   
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}





@media screen and (min-width: 0px) and (max-width: 576px) {
    .fsm{
        font-size: 13px !important;
    }
    .microfact-carousal::before{
        display: none;
    }
    .microfact-carousal2::before{
        display: none;
    }
    .fp.mt-2{
        padding: 20px !important;
    }
    .shade{
        display: none;
    }
    .fh{
        font-size: 18px;
    }
    .manufact_m_pad{
        padding: 3rem 0 0 0 !important;
        margin: 0 !important;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .fsm{
        font-size: 13px !important;
    }
    .microfact-carousal::before{
        display: none;
    }
    .microfact-carousal2::before{
        display: none;
    }
    .fp{
        padding: 20px !important;
    }
    .shade{
        display: none;
    }
    .fh{
        font-size: 18px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}
