.imprint-banner {
    position: relative;

}

.imprint-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /* background-image: url('../images/imprint-banner.png'); */
    background-color: #313D44;
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.imprint-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 250px;
}
.imprint-section{
    display: block;
    background-image: url('../images//section-bg-banner.png');
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 100px;
    background-size: 25%;

    /* float: right; */
}



@media screen and (min-width: 576px) and (max-width: 768px) {
    .imprint-section {
        background-image: none !important;
        display: none;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
    
}