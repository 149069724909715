.partners-banner {
    position: relative;

}

.partners-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/partners-header-banner.png');
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 300px;
}

.partner-drop{
    color: black;
    font-weight: bold;
    background-color: #CBD62E;
    height: 50px;
    width: 50px;
    position: absolute;
    background-size: 50px;
    right: 20px;
    top: -30px;
    /* border-radius: 50% 0px 50% 50%; */
    font-size: 12px;
    padding: 6px;
    padding-top: 16px;  
    clip-path: inset(0 0 0 0);

}

.partner-carousal-image{
    height: 451px;
}
.partner_content{
    /* background-color: red !important; */
    height: 210px;
    margin-bottom: 20px;
}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .partner-carousal-image{
        height: 251px;
    }
    .partner_content{
        /* background-color: red; */
        height: auto;
        font-size:small;

    }
    .partners-card{
        margin: auto !important;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
    .partners-card{
        margin: auto !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .top-nav{
        background-color: #313D44 !important;
    }
    .partners-card{
        margin: auto !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}