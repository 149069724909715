.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-gallery-image {
  position: relative;
}

.image-gallery-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-gallery-image:hover .image-gallery-description {
  opacity: 1;
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  width: 75% !important;
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-left {
  display: inline-block;
  width: 75% !important;
}

.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
  max-height: 75vh !important;
}

.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image {
  max-height: 75vh !important;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  vertical-align: top;
  width: 23% !important;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left {
  display: inline-block;
  vertical-align: top;
  width: 23% !important;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: cover !important;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
  width: 100% !important;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
  width: 100% !important;
}

.spl-tabs .nav-link.active {
  background-color: #CBD62E !important;
  color: black;
}

.spl-tabs .nav-link {
  background-color: #182838 !important;
  border-radius: 2px;
  color: white !important;
}

.scrollable-list::-webkit-scrollbar {
  width: 20px;
  height: 7px;
}

/* Track */
.scrollable-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollable-list::-webkit-scrollbar-thumb {
  background: #CBD62E;
  border-radius: 10px;
}

/* Handle on hover */
.scrollable-list::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* @media (max-width: 768px) {

  .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
  .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: calc(100% - 87px) !important;
  }

} */
@media screen and (min-width: 0px) and (max-width: 576px) {
  .spl-tabs {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    width: 100% !important;
  }

  .spl-tabs li {
    text-wrap: nowrap;
  }

  .camp-tabs {
    padding: 3rem 0 0 0 !important;
  }

}

/* .scrollable-list::-webkit-scrollbar-thumb {
  background: red !important;
  border-radius: 5px !important;
} */

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .spl-tabs {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    width: 100% !important;
  }

  .spl-tabs li {
    text-wrap: nowrap;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}