.navbar-nav .active .nav-link {
    color: #CBD62E !important;
    /* font-weight: bold; */
}
.navbar-nav .nav-link {
   white-space: nowrap;
}
/* Add this CSS to your stylesheet */
.navbar-nav .nav-item.dropdown.active > .nav-link {
    color: red;
}
/* .nav-link{
    font-size: 19px;
} */



.mainactive{
    color: #B9D738 !important;
    /* Change this to your desired color */
    font-weight: bold;
}

.banner {
    position: relative;

}
.bg-blackColor {
    background-color: #313D44 !important;
}
.banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/banner-bg.png');
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 670px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: -1;
    filter: grayscale(70);
}
.banner::before{
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 670px;
} 
.banner2 {
    mix-blend-mode: none;
}

.dropdown-menu {
    display: none;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}
.navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.navbar-toggler{
    border: 1px solid white !important;
}
.deMenu{
    padding: 1.5rem !important;
    padding-top: 1.5rem !important;
}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .home_banner {
        width: 75% !important;
        margin-top: 520px !important;
        position: absolute !important;
        margin-right: 20px !important;
        font-size: smaller;
    }
    .home_banner .card-body .card-title{
        font-size: larger;
    }
    .banner-enqury-btn{
        font-size: smaller;
    }
    .banner-watch-btn{
        font-size: smaller;
    }

    .top-nav{
        background-color: #313D44 !important;
    }
    /* .logo{
        width: 65% !important;
    } */
    .enmenuMob{
        padding: 1.5rem !important;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
   
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .top-nav{
        background-color: #313D44 !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}