.placeholder-white::placeholder {
    color: white !important;
}

.footer_container::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #D5D5D5;
    width: 100%;
    height: 100%;
    z-index: -1;
}





@media screen and (min-width: 0px) and (max-width: 576px) {
    .cpywrt{
        text-align: center !important;
        font-size: medium;
    }
    .cpylink{
        text-align: center !important;
    }
    .footer-logo{
        width: 65%;
    }
    
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}