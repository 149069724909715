.video-popup {
  position: fixed;
  top: calc(670px * 0.3);
  right: 1%;
  width: 290px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: move;
}

.video-popup video {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-popup .watch-button {
  margin-top: 12px;
  padding: 7px 10px;
  background: rgb(203, 214, 46);
  color: #000;
  font-size: 12px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.video-popup .close-button {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 19px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .video-popup {
      right: 5%;
      width: 60%;
      padding: 10px;
      border-radius: 8px;
  }

  .video-popup .watch-button {
      font-size: 12px;
      padding: 5px 10px;
      margin-top: 8px;
  }

  .video-popup .close-button {
      width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 8px;
  }
}
