.about-banner {
    position: relative;

}

.about-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/AboutBanner.png');
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.about-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 300px;
}

.banner2 {
    mix-blend-mode: none;
}


.abt-banner {
    background-image: url('../images/abt-bg-banner.png');
    background-repeat: no-repeat;
}

.abt-banner-content {
    color: white;
    padding: 30px;
    width: 130%;
    height: 140px;
    background-color: #55585A;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-left: -30%;
    text-align: center;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 18% 100%);
}

/* .abt-banner-content::before{
    content: '..';
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 300px;

} */




.abt-modal-carousal .carousel-control-next {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    top: unset;
}

.abt-modal-carousal .carousel-control-prev {
    position: absolute;
    right: 70px;
    left: unset;
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    top: unset;
    color: white;
}

.abt-modal-carousal .carousel-indicators {
    margin-bottom: 5rem !important;
}

.abt-modal-carousal .carousel-control-next span,
.abt-modal-carousal .carousel-control-prev span {
    filter: unset !important;
}

.abt-modal-carousal {
    padding-bottom: 4rem;
}

.abt-modal-carousal .carousel-indicators button {
    width: 20px;
    height: 20px !important;
    border-radius: 50px;
    background: #D5D5D5 !important;
    border: unset !important;

}

.abt-modal-carousal .carousel-indicators button.active {
    background: #B9D738 !important;
    width: 30px;
}



.testimonial_container {
    /* width: 100% !important; */
}

.testimonial_shadow .carousel-item .row .col-12 p {
    font-size: 20px !important;
}


.abt-mission {
    background-image: url('../images/banner-about-mission.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    background-color: black;
}




.img-card2 {
    overflow: hidden;
    position: relative;
    padding-bottom: 58%;
}

.image-card-overlay2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
}

.img-card2 img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
}

.hover-content2 {
    position: relative;
    z-index: 99;
}


.image-card-hover2 .image-card-overlay2 {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.image-card-title2 {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 99;
    transition: all 0.3s;
}

.image-card-description2 {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    left: 0;
    /* text-align: center; */
    z-index: 99;
    transition: all 0.3s;
}

.image-card-hover2:hover .image-card-title2 {
    transform: translateY(-1.5rem);
}

.image-card-hover2:hover .image-card-description2 {
    bottom: 0.5rem;
    opacity: 1;
}

.image-card-hover2:hover .image-card-overlay2 {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
}

.Nachhaltiges_marketing {
    background-image: url('../images/nachhaltiges_marketing-bg.jpg');
}

/* 
.testimonial_carousal .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon{
    filter:unset
} */

.successStory_arrow_next {
    background-image: url('../images/leftArrow_rounded.png');
}

.successStory_arrow_prev {
    background-image: url('../images/rightArrow_rounded.png');
}


@media screen and (min-width: 0px) and (max-width: 576px) {
    .about_banner_content {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .about_banner_content .text-center {
        width: 100% !important;
    }

    .about_mission .text-center h5 {
        font-size: 13px !important;
    }

    .abt-banner-content {
        margin-left: -25%;
    }

    .testimonial_carousal {
        width: 100% !important;
    }

    .testimonial_container {
        width: 100% !important;
    }

    .testimonial_container .carousel-control-prev .carousel-control-prev-icon {
        filter: unset;
    }

    .testimonial_container .carousel-control-next .carousel-control-next-icon {
        filter: unset;
    }
    .m_width{
        width: 100% !important;
    }

}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .testimonial_carousal {
        width: 100% !important;
    }

    .testimonial_container {
        width: 100% !important;
    }

    .testimonial_container .carousel-control-prev .carousel-control-prev-icon {
        filter: unset;
    }

    .testimonial_container .carousel-control-next .carousel-control-next-icon {
        filter: unset;
    }

    .testimonial-img {
        width: 100% !important;
    }

}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}