:root {
  --main-color: #CBD62E;
  --secondairy-color: #054739;
  --secondairy-gray: #55585a;
  --secondairy-gray-lite: #F6F6F6;
  background-color: #07121D;
}

@font-face {
  font-family: 'Avenir LT Std 35 Light';
  src: url('../src/assets/fonts/AvenirLTStd-Light.eot');
  /* IE9 Compat Modes */
  src: url('../src/assets/fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../src/assets/fonts/AvenirLTStd-Light.woff') format('woff'),
    /* Modern Browsers */
    url('../src/assets/fonts/AvenirLTStd-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../src/assets/fonts/AvenirLTStd-Light.svg#a3894d49b0c62da6d619d8a85ab36753') format('svg');
  /* Legacy iOS */

  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('../src/assets/fonts/AvenirLTStd-Black.eot');
  /* IE9 Compat Modes */
  src: url('../src/assets/fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../src/assets/fonts/AvenirLTStd-Black.woff') format('woff'),
    /* Modern Browsers */
    url('../src/assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../src/assets/fonts/AvenirLTStd-Black.svg#2d55e36446b90be6f85f84e74f83cb6c') format('svg');
  /* Legacy iOS */

  font-style: normal;
  font-weight: 400;
}



body {
  overflow-x: hidden;
  background-color: #07121D !important;

}

p,
li,
a {
  font-family: 'Avenir LT Std 35 Light' !important;
}

h1,
h2,
h3,
h4 {
  font-family: 'Avenir LT Std' !important;
}

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.custom-tag {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  border: 2px solid #ffc107;
  border-radius: 10px;
  color: #fff;
  background-color: #343a40;
}

/* Placeholder styling */
.image-placeholder {
  width: 54px;
  height: 19px;
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}


.eagle-page-container .eagle-page .eagle-page-visible {
  margin-left: 100px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.scrolltopbtn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #CBD62E !important;
  z-index: 998;
}
.dropdown-item:hover{
  background-color: #CBD62E !important;
}
.dropdown-item.active, .dropdown-item:active {
  color: black !important;
  text-decoration: none !important;
  background-color: #CBD62E !important;
}



.overlay-content {
  pointer-events: none; /* Disable clicking on the overlay content */
}
.img-card {
  overflow: hidden;
  position: relative;
  padding-bottom: 80%;
}

.image-card-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.img-card img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}


.image-card-hover .image-card-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.image-card-title {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 99;
  transition: all 0.3s;
}

.image-card-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  left: 0;
  /* text-align: center; */
  z-index: 99;
  transition: all 0.3s;
}

.image-card-hover:hover .image-card-title {
  transform: translateY(-1.5rem);
}

.image-card-hover:hover .image-card-description {
  bottom: 0.5rem;
  opacity: 1;
}

.image-card-hover:hover .image-card-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
}


@media screen and (min-width: 0px) and (max-width: 576px) {
  .scrolltopbtn {
    position: fixed !important;
    right: 20px !important;
    background-color: #CBD62E !important;
    z-index: 999 !important;
  }

  .HeaderBanner_img {
    margin-top: 50px !important;
  }

  .imprint-section {
    background-image: none !important;
    display: none;
}

 
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}


