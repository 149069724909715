.splevent-banner {
  position: relative;

}

.splevent-banner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('../images/splevent-header-banner.png');
  /* Updated image path */
  background-size: cover;
  /* Ensure the background covers the entire element */
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  /* Prevent horizontal repeating */
  background-position: center;
  z-index: 1;
}

.splevent-banner::before {
  content: "";
  background-color: #000000a0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  height: 300px;
}

.spl-content {
  /* line-height: 3 !important; */
  font-size: 20px;
  text-align: justify;
}

.spl-banner {
  margin-left: -80px;
  margin-top: 50px;
  width: auto;
  height: 100%;
  position: relative;
  width: calc(80% + 56%);
}


/* .genuss {
    width: 100%;
    height: 100%;
  }
  
  .genuss-slide {
    text-align: center;
    font-size: 18px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .genuss-slide img {
    display: block;
    width: 90%;
    height: 90%;
    object-fit: cover;
  }
  
  .genuss {
    width: 100%;
    height: 370px;
    margin: 20px auto;
    padding: 10px !important;
    padding-bottom: 80px !important;
    padding-top: 30px !important;
  }
  .append-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #007aff;
    color: #007aff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
  }
.genuss-button-next{
      top: 338px !important;
      width: 6% !important;
      margin: auto !important;
      left: 131px !important;
      right: 0 !important;
  
}
  .genuss-button-next:after {
    content: '' !important;
    background-image: url('../images/ArrowithBlackCircle-right.png');
    height: 60px;
    width: 60px;
    background-size: 40px;
    background-repeat: no-repeat;
}

.genuss-button-prev{
  top: 338px !important;
  width: 6% !important;
  margin: auto !important;
  left: 0px !important;
  right: 100px !important;

}

.genuss-button-prev:after {
content: '' !important;
background-image: url('../images/ArrowithBlackCircle-left.png');
height: 60px;
width: 60px;
background-size: 40px;
background-repeat: no-repeat;
}

.genuss-pagination {
  z-index: -1 !important;
}

.carotext{
  visibility: hidden;
}
.genuss-slide-active .carotext{
  visibility: visible;
}

.genuss-slide-active .caroimg{
  display: block;
    width: 100%;
    height: 100%;
    padding-right: 45px !important;
    object-fit: cover;
}

 */

.genuss {
  padding-top: 30px !important;
  padding-bottom: 70px !important;
}

/* .genuss-slide img {
  display: block;
  width: 70%;
  object-fit: cover;
} */

.genuss-slide-active .swip-container {
  margin-top: -40px;
}

.genuss-slide-active .swip-container .caroimg {
  width: 100%;
}

.caroimg {
  width: 70%;
}

.genuss-button-next {
  bottom: -170px !important;
  width: 6% !important;
  margin: auto !important;
  left: 131px !important;
  right: 0 !important;
}

.genuss-button-next:after {
  content: '' !important;
  background-image: url('../images/ArrowithBlackCircle-right.png');
  height: 60px;
  width: 60px;
  background-size: 40px;
  background-repeat: no-repeat;
}

.genuss-button-prev {
  bottom: -170px !important;
  width: 6% !important;
  margin: auto !important;
  left: 0px !important;
  right: 100px !important;

}

.genuss-button-prev:after {
  content: '' !important;
  background-image: url('../images/ArrowithBlackCircle-left.png');
  height: 60px;
  width: 60px;
  background-size: 40px;
  background-repeat: no-repeat;
}

.genuss-pagination {
  z-index: -1 !important;
}

.carotext {
  visibility: hidden;
}

.genuss-slide-active .carotext {
  visibility: visible;
}



@media screen and (min-width: 0px) and (max-width: 576px) {
  .genuss-button-next {
    bottom: -123px !important;
    width: 40% !important;
    margin: auto !important;
    left: 131px !important;
    right: 0 !important;
  }

  .genuss-button-prev {
    bottom: -123px !important;
    width: 40% !important;
    margin: auto !important;
    left: -90px !important;
    right: 0 !important;
  }

  .text-line {
    display: none;
  }
  .spl-content{
    line-height: unset !important;
    font-size: 12px;
  }
  .spl-content::after{
    display: none;
  }
  .spl-content::before{
    display: none;
  }
  .spl-carousal{
    margin-top: 20px !important;
  }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .text-line {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .text-line {
    display: none;
  }
  .genuss-button-next {
    bottom: -133px !important;
    width: 40% !important;
    margin: auto !important;
    left: 131px !important;
    right: 0 !important;
  }

  .genuss-button-prev {
    bottom: -133px !important;
    width: 40% !important;
    margin: auto !important;
    left: -90px !important;
    right: 0 !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}