.shop-banner {
    position: relative;

}

.shop-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    /* background-image: url('../images/imprint-banner.png'); */
    background-color: #313D44;
    /* Updated image path */
    background-size: cover;
    /* Ensure the background covers the entire element */
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    /* Prevent horizontal repeating */
    background-position: center;
    z-index: 1;
}

.shop-banner::before {
    content: "";
    background-color: #000000a0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 250px;
}
.shop-section{
    /* background-image: url('../images//section-bg-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 100px; */

    /* float: right; */
}