.home-mission {
    margin-top: 15rem;
}

.missionImg-container {
    position: relative;
    display: inline-block;
}

.missionImg-container .img_border {
    position: absolute;
    top: 0;
    left: 0;
    width: 227px;
    height: 227px;
    border: 1px solid var(--main-color);
    ;
    transform: rotate(21deg);
    box-sizing: border-box;
    z-index: -1;
    /* border-radius: 25px; */
}



.partners_container {
    background-color: var(--secondairy-color);

}

.parter {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background: aliceblue;
    padding: 12px;
    width: 70%;
}

.partner_content {
    background: var(--main-color);
    width: 57%;
    margin: auto;
    margin-top: -62px;
}

.partner-carousal-prvicon {
    background-image: url('../images/green-left-arrow.png');
    filter: unset !important;
}


.partner-carousal-nxticon {
    background-image: url('../images/green-right-arrow.png');
    filter: unset !important;
}

.sjc {
    opacity: 1 !important;
    width: 25%;
}

.client-logo img {
    object-fit: contain;
    height: 100px;
    width: 100%;
    padding: 20px;
}

.ourservices_container {
    background-color: var(--secondairy-gray);
}

.light_gray {
    background-color: var(--secondairy-gray-lite);
}

.our-services .react-multiple-carousel__arrow--right {
    background-color: var(--main-color);

}

.our-services .react-multiple-carousel__arrow--left {
    background-color: var(--main-color);
}

.our-services .react-multiple-carousel__arrow--right::before,
.our-services .react-multiple-carousel__arrow--left::before {
    filter: invert(1) grayscale(100);

}

.catalog-iframe {
    height: 600px
}

.catalog-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 60%;
    width: 100%;
    background-color: #D9D9D9;
    z-index: -1;
}

.catalog::after {
    content: '';
    position: absolute;
    background-image: url('../images/catlog-logo.png');
    background-repeat: no-repeat;
    height: 30%;
    width: 17%;
    bottom: -91px;
    /* left: -14px; */
    right: 0;
}

.factcar .react-multiple-carousel__arrow--right {
    right: calc(0% + 0px) !important;

}

.factcar .react-multiple-carousel__arrow--left {
    left: calc(0% + 0px) !important;

}


/* css v2 */

.testimonial-bg{
    background-image: url('../images/testimonialBg1.png');
    background-size: cover;
}
.testimonial-bg2{
    background-image: url('../images/testimonialBg2.png');
    background-size: cover;
}
.testimonial-bg3{
    background-image: url('../images/testimonialBg3.png');
    background-size: cover;
}
.catalog-container{
    background-image: url('../images/catalogBg.png');
    background-size: cover;
}
.img_parent {
    width: 65%;
    /* height: 100%; */
    background: linear-gradient(to right, #cbd62e 0px, transparent 0px) 0 0, linear-gradient(to right, #cbd62e 100%, transparent 100%) 0 100%, linear-gradient(to left, #cbd62e 100%, transparent 100%) 100% 0, linear-gradient(to bottom, #cbd62e -4%, transparent 0px) 0 0, linear-gradient(to bottom, #cbd62e 20px, transparent 20px) 100% 0, linear-gradient(to top, #cbd62e 20px, transparent 20px) 0 100%;
    background-repeat: no-repeat;
    background-size: 125px 125px;
}
.stamp-logo{
    width: 75%;
}
.section-padding{
    padding: 10px  10px;
}
.catalog-download-btn{
    border-radius: 17px 0px 17px 0px !important
}

.factory_carousal{
    position: unset !important;
}
.factory_carousal .react-multiple-carousel__arrow{
    background: transparent !important;
    z-index: 999 !important;
}
.factory_carousal .react-multiple-carousel__arrow::before{
    font-size: 30px;
    color: gainsboro;
    opacity: 0.9;
}
.factory_carousal .react-multiple-carousel__arrow--right{
    right: calc(7% + 6px) !important;
}
.factory_carousal .react-multiple-carousel__arrow--left{
    left: calc(7% + 6px) !important;
}

/* .factory_carousal .react-multiple-carousel__arrow{
    border: 2px solid #CBD62E !important;
}
.factory_carousal .react-multiple-carousel__arrow--left::before {
    content: "" !important;
    background-image: url('../images/leftArrow.png');
    width: 27px !important;
    height: 27px !important;
    background-repeat: no-repeat;
    background-position: center;
}
.factory_carousal .react-multiple-carousel__arrow--right::before {
    content: "" !important;
    background-image: url('../images/rightArrow.png');
    width: 27px !important;
    height: 27px !important;
    background-repeat: no-repeat;
    background-position: center;
} */


.home-campains{
    background-image: url('../images/homeBgCampains.png');
        /* Ensure the background covers the entire viewport */
        background-size: cover;
        /* Ensure the background fills the entire element */
        background-repeat: no-repeat;
        /* Position the background at the center */
        background-position: center;
        /* Set the height and width to cover the entire viewport */
        /* height: 450px;
        width: 100%; */
        /* mix-blend-mode: luminosity; */
}

.eagle-page.eagle-page-visible{
    display: none !important;
    width: 768px;
    height: 543px;
    margin-left: 156px !important;
}
@media screen and (min-width: 0px) and (max-width: 576px) {

    .home-mission .row .col {
        margin-top: 50px;
    }


    .partner_img_container {
        width: 50% !important;
    }

    .partner_content p {
        padding: 75px 10px 10px 10px !important;
    }

    .partner_content {
        width: 85% !important;
    }

    .partner_arrow_content {
        display: none;
    }

    .ourservices_content {
        left: 0 !important;
        padding-bottom: 3rem !important;
    }

    .text-line {
        display: none !important;
    }

    .catalog {
        width: 100% !important;
    }

    .catalog-iframe {
        /* height: 160px !important; */
        height: 300px !important;

    }

    .catalog::after {

        display: none;
    }
    .stamp-logo{
        width: 100px;
    }
    .HomeBannerIcons{
        display: none !important;
    }

    .catalog-download-btn{
        padding: 5px !important;
        font-size: 10px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

/* Small devices (portrait phones, 576px and up) */
@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .text-line {
        display: none;
    }

    .catalog::after {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width: 1200px) {}